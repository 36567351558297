import { useEffect, useMemo, useRef, useState } from "react";

const persistentStateEventTarget = new EventTarget();

export function usePersistentState(key, initialState) {
  // We use a ref to store the identity of the hook
  // this way we can filter out events that are triggered
  // by the hook instance itself
  const hookIdentity = useRef(() => ({}));

  // Get the stored value from local storage, as we primarily
  // use this to compare the value with the current value
  // we don't parse the value here
  const [storageValue, setStorageValue] = useState(() => {
    return getStoredValue(key);
  });

  // If the storage value is null, the value will be initialized
  // otherwise the value will be set to the parsed storage value
  // this could be different from the expected value
  const [value, setValue] = useState(() => {
    return valueInitializer(storageValue, initialState);
  });

  // If the key changes, we re-initialize the state
  // we don't want to re-initialize the state on the initial mount
  // as this is already handled by the above useState hooks
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const initialStorageValue = getStoredValue(key);
    setStorageValue(initialStorageValue);
    setValue(valueInitializer(initialStorageValue, initialState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  // update local storage when value changes
  const stringifiedValue = useMemo(() => JSON.stringify(value), [value]);
  useEffect(() => {
    // We have memoized the stringified value to not have to stringify it
    // for comparison every time the component re-renders
    if (stringifiedValue !== storageValue) {
      const storageKey = getPrefixedKey(key);
      localStorage.setItem(getPrefixedKey(key), stringifiedValue);
      setStorageValue(stringifiedValue);
      persistentStateEventTarget.dispatchEvent(
        new CustomEvent("update", {
          detail: {
            value,
            storageValue: stringifiedValue,
            storageKey,
            sender: hookIdentity,
          },
        }),
      );
    }
  }, [stringifiedValue, storageValue, value, key]);

  // Listen for localStorage changes (e.g., from another window or tab)
  useEffect(() => {
    function handleStorage(event) {
      if (event.key !== getPrefixedKey(key)) return;
      try {
        if (event.newValue === null) {
          // If the item is removed from localStorage we do nothing.
          return;
        } else {
          const parsedNewValue = parseValue(event.newValue);
          setStorageValue(event.newValue);
          setValue(parsedNewValue);
        }
      } catch (err) {
        console.error("Failed to parse storage value", err);
      }
    }
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, [key]);

  // Listen for changes to the value by other hooks
  useEffect(() => {
    function handleEvent(event) {
      if (event.detail.sender === hookIdentity) return;
      if (event.detail.storageKey === getPrefixedKey(key)) {
        setValue(event.detail.value);
        setStorageValue(event.detail.storageValue);
      }
    }
    persistentStateEventTarget.addEventListener("update", handleEvent);
    return () =>
      persistentStateEventTarget.removeEventListener("update", handleEvent);
  }, [key]);

  return [value, setValue];
}

function getStoredValue(key) {
  return localStorage.getItem(getPrefixedKey(key));
}

function valueInitializer(storageValue, initialState) {
  const getInitialState = () =>
    typeof initialState === "function" ? initialState() : initialState;

  // If the storage value is null, we initialize state
  if (storageValue === null) {
    return getInitialState();
  }

  // If the storage value is not null, we try to parse it
  // and if it fails we do initialize state nevertheless
  try {
    return parseValue(storageValue);
  } catch (err) {
    console.error("Failed to parse storage value, using initial state", err);
    return getInitialState();
  }
}

function getPrefixedKey(key) {
  return "use-persistent-state-" + key;
}

// This function can be used to get a stored value outside of the hook
// If the value can not be parsed, null is returned
export function getStoredPersistentStateValue(key) {
  const prefixedKey = getPrefixedKey(key);
  const storedValue = localStorage.getItem(prefixedKey);
  try {
    return parseValue(storedValue);
  } catch (err) {
    console.error("Failed to parse stored value", err);
    return null;
  }
}

function parseValue(value) {
  return value === null ? null : JSON.parse(value);
}
