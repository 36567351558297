import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, throttle } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  TEST_CLIPS_API_FAILURE,
  TEST_CLIPS_API_REQUEST,
  TEST_CLIPS_API_SUCCESS,
} from "@/reducers/testClipsApi";
import { TEST_CLIPS_PARAM_NAME } from "@/routes/loaders/testClips";
import { API_URL } from ".";

import { parseTestClipsApi } from "./parsers";

export function* watchTestClips() {
  yield throttle(1000, TEST_CLIPS_API_REQUEST, callTestClipsApi);
}

function* callTestClipsApi(action: any): SagaIterator {
  const {
    testId,
    page,
    selectedTesterIds,
    selectedTaskIds,
    selectedTags,
    isUntaggedSelected,
    isShowAi,
  } = action;
  try {
    const response = yield call(
      fetchTestClips,
      testId,
      page,
      selectedTesterIds,
      selectedTaskIds,
      selectedTags,
      isUntaggedSelected,
      isShowAi,
    );

    const parsedData = parseTestClipsApi(response.data);

    yield put({
      type: TEST_CLIPS_API_SUCCESS,
      // TODO: think about how to best handle parse failures.
      // Andreas: Errors could be catched here and dispatched as a failure action.
      data: {
        ...parsedData,
        testId,
        page,
        selectedTesterIds,
        selectedTaskIds,
        selectedTags,
        isUntaggedSelected,
      },
    });
  } catch (error) {
    yield put({
      type: TEST_CLIPS_API_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function fetchTestClips(
  testId: number,
  page: number,
  selectedTesterIds: number[] | null,
  selectedTaskIds: number[] | null,
  selectedTags: string[] | null,
  isUntaggedSelected: boolean | null,
  isShowAi: boolean | null,
) {
  // The server expects params to be as follows:
  // 1. Arrays -> fooParam=bar,baz,quz
  // 2. Booleans -> fooParam=1 (and omit for false)

  const params = new URLSearchParams();
  const paramPairs: [string, boolean | number | number[] | string[] | null][] =
    [
      [TEST_CLIPS_PARAM_NAME.PAGE, page],
      [TEST_CLIPS_PARAM_NAME.TESTER_IDS, selectedTesterIds],
      [TEST_CLIPS_PARAM_NAME.TESTSTEP_IDS, selectedTaskIds],
      [TEST_CLIPS_PARAM_NAME.TAGS, selectedTags],
      [TEST_CLIPS_PARAM_NAME.UNTAGGED, isUntaggedSelected ? 1 : null],
      [TEST_CLIPS_PARAM_NAME.SHOW_AI, isShowAi === false ? 0 : null],
    ];

  paramPairs.forEach(([paramName, paramValue]) => {
    if (paramValue !== null) {
      params.set(paramName, paramValue.toString());
    }
  });

  return axios({
    method: "get",
    url: `${API_URL}/test/${testId}/clips?${params.toString()}`,
  });
}
