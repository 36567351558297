import React from "react";
import { cn } from "@sys42/utils";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class ToggleButton extends React.Component {
  static defaultProps = { styles };

  render() {
    const {
      checked,
      defaultChecked,
      disabled,
      children,
      className,
      onChange,
      styles,
      value,
    } = this.props;

    return (
      <label
        {...extractContainerProps(this.props)}
        className={cn(
          className,
          styles.toggleButton,
          disabled && styles.disabled,
        )}
      >
        <input
          type="checkbox"
          onChange={onChange}
          value={value}
          checked={checked}
          disabled={disabled}
          defaultChecked={defaultChecked}
        />
        <span className={`${styles.slider} ${styles.round}`} />
        {children && <span className={styles.label}>{children}</span>}
      </label>
    );
  }
}

export default ToggleButton;
