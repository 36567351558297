import { createContext, useContext, useEffect, useState } from "react";
import { cn } from "@sys42/utils";
import find from "lodash/find";
import { Link, useRevalidator, useSearchParams } from "react-router-dom";

import ConfirmModal from "@/components/ConfirmModal";
import { ImageLoader, ImageLoaderFallback } from "@/components/ImageLoader";
import { Modal2 } from "@/components/Modal";
import { useModal2Controller } from "@/components/Modal/hooks";
import { NoteWithHashtags } from "@/components/NoteWithHashtags";
import { ShareClip } from "@/containers/ShareClip";
import {
  InlineIcon,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
} from "@/design-system";
import { pluralizer } from "@/helpers";
import { toHHMMSS, usePrevious } from "@/helpers-ts";
import { VIDEO_CLIP_DELETE_REQUEST } from "@/reducers/videoClipDelete";
import { useAppDispatch, useAppSelector } from "@/store";

import { Filters } from "./Filters";
import { ClipsProps, ListItemProps } from "./types";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconPlay } from "./icons/icon-play.svg";
import { ReactComponent as SvgIconShared } from "./icons/icon-shared.svg";

const ClipsContext = createContext(
  {} as {
    onClickShare: (clipId: number) => void;
    onClickDelete: (clipId: number, videoId: number) => void;
    onClickDownload: (url: string) => void;
  },
);

/**
 * Clips
 */
export function Clips({
  clips,
  isFetching,
  isUntaggedSelected,
  page,
  perPage,
  selectedTags,
  selectedTaskIds,
  selectedTesterIds,
  tagless,
  tags,
  tasks,
  testers,
  total,
  unfilteredTotal,
}: ClipsProps) {
  const dispatch = useAppDispatch();
  const revalidator = useRevalidator();
  const [searchParams, setSearchParams] = useSearchParams();

  const [sharedClipId, setSharedClipId] = useState<number | null>(null);
  const sharedClip = find(clips, (clip) => clip.id === sharedClipId) ?? null;
  const {
    open: showConfirmShareModal,
    close: hideConfirmShareModal,
    isOpen: isShareModalOpen,
  } = useModal2Controller();

  const [deleteClipIds, setDeleteClipIds] = useState<
    [clipId: number, videoId: number] | null
  >(null);
  const isDeleteFetching = useAppSelector(
    (state) => state.videoClipDelete.isFetching,
  );
  const prevIsDeleteFetching = usePrevious(isDeleteFetching);
  const isDeleteError = useAppSelector(
    (state) => state.videoClipDelete.isError,
  );
  const {
    open: showConfirmDeleteModal,
    close: hideConfirmDeleteModal,
    isOpen: isDeleteModalOpen,
  } = useModal2Controller();

  function handleCloseShareModal() {
    setSharedClipId(null);
    hideConfirmShareModal();
  }

  function handleClickShare(clipId: number) {
    setSharedClipId(clipId);
    showConfirmShareModal();
  }

  function handleClickRequestDelete(clipId: number, videoId: number) {
    setDeleteClipIds([clipId, videoId]);
    showConfirmDeleteModal();
  }

  function handleClickConfirmDelete() {
    deleteClipIds &&
      dispatch({
        type: VIDEO_CLIP_DELETE_REQUEST,
        clipId: deleteClipIds[0],
        videoId: deleteClipIds[1],
      });
  }

  function handleClickDownload(url: string) {
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleClickPage(newPageNumber: number) {
    searchParams.set("page", newPageNumber.toString());
    setSearchParams(searchParams, { replace: false });
  }

  useEffect(() => {
    if (!isDeleteFetching && prevIsDeleteFetching) {
      const payload = {
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: "Failed to delete clip.",
      };
      if (!isDeleteError) {
        payload.content = "Clip deleted successfully.";
        payload.notificationType = "success";
        revalidator.revalidate();
      }
      dispatch(payload);

      hideConfirmDeleteModal();
      setDeleteClipIds(null);
    }
  }, [
    hideConfirmDeleteModal,
    revalidator,
    dispatch,
    isDeleteError,
    isDeleteFetching,
    prevIsDeleteFetching,
  ]);

  const deleteModalElement = (
    <ConfirmModal
      children={"Are you sure you want to delete this clip?"}
      isActive={isDeleteModalOpen}
      isConfirmDisabled={isDeleteFetching}
      labelConfirm={isDeleteFetching ? "Deleting…" : "Delete"}
      onClose={hideConfirmDeleteModal}
      onConfirm={handleClickConfirmDelete}
    />
  );

  const shareModalElement = (
    <Modal2
      centered
      content={
        sharedClip ? (
          <ShareClip {...sharedClip} onClickClose={handleCloseShareModal} />
        ) : null
      }
      isActive={isShareModalOpen}
      maxWidth={"32.5rem"}
      onClose={handleCloseShareModal}
    />
  );

  const emptyClipsPlaceholderElement = (
    <div className={styles.emptyClipsPlaceholder}>
      All clips have been filtered out. Please adjust your filters to find what
      you're looking for.
    </div>
  );

  return (
    <ClipsContext.Provider
      value={{
        onClickShare: handleClickShare,
        onClickDelete: handleClickRequestDelete,
        onClickDownload: handleClickDownload,
      }}
    >
      {shareModalElement}
      {deleteModalElement}
      <h2 className={styles.header}>
        {pluralizer("clip")(unfilteredTotal, true)}
      </h2>
      <Filters
        className={styles.filters}
        tasks={{
          active: selectedTaskIds,
          options: tasks,
        }}
        testers={{
          active: selectedTesterIds,
          options: testers,
        }}
        tags={{
          active: selectedTags,
          isTaglessNotesCount: tagless,
          isTaglessNotesSelected: !!isUntaggedSelected,
          options: tags,
        }}
      />
      {clips.map((clip, index) => (
        <ListItem key={index} isFetching={isFetching} {...clip} />
      ))}
      {!Boolean(clips.length) && emptyClipsPlaceholderElement}
      {total > perPage && (
        <Pagination
          className={styles.pagination}
          countPages={Math.ceil(total / perPage)}
          currentPage={page || 1}
          onClickPage={handleClickPage}
        />
      )}
    </ClipsContext.Provider>
  );
}

function ListItem({
  author,
  downloadClipUrl,
  id,
  isFetching,
  isPublic,
  isVideoReady,
  note,
  tester,
  timestampEnd,
  timestampStart,
  videoId,
  videoThumbnailUrl,
  isAutomatedInsight,
}: ListItemProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const videoTo = `/video/${videoId}?clip=${id}`;
  const videoToAutoplay = `${videoTo}&autoplay=1`;

  const { onClickShare, onClickDelete, onClickDownload } =
    useContext(ClipsContext);

  const handleOpen = () => setIsMenuOpen(true);
  const handleClose = () => setIsMenuOpen(false);

  const videoDurationElement = (
    <div className={styles.listItemVideoDuration}>
      {toHHMMSS(timestampEnd - timestampStart)}
    </div>
  );

  const publicMetaElement = (
    <div
      className={styles.listItemMeta}
      title={"Anyone with the link can view"}
    >
      ·&nbsp;
      <InlineIcon Svg={SvgIconShared} />
    </div>
  );

  const testerMetaElement = (
    <div className={styles.listItemMeta}>{tester.replace(".", "")}&nbsp;</div>
  );

  const authorMetaElement = (
    <div
      className={cn(
        styles.listItemMeta,
        isAutomatedInsight && styles.listItemMeta_ai,
      )}
    >
      ·&nbsp;by&nbsp;<span>{author}</span>&nbsp;
    </div>
  );

  const thumbnailFallbackElement = (
    <ImageLoaderFallback
      className={styles.listItemVideoThumbnailFallbackIcon}
    />
  );

  const thumbnailElement = (
    <ImageLoader
      imageClassName={styles.listItemVideoThumbnailImage}
      fallback={thumbnailFallbackElement}
      url={videoThumbnailUrl as string}
    />
  );

  const generatingFeedbackElement = (
    <span className={styles.listItemVideoThumbnailGeneratingFeedback}>
      Generating…
    </span>
  );

  return (
    <div
      className={cn(
        styles.listItem,
        isFetching && styles.listItem_fetching,
        isMenuOpen && styles.listItem_menuOpen,
      )}
    >
      <Link to={videoToAutoplay} className={styles.listItemLink}>
        <div className={styles.listItemVideoThumbnail}>
          <SvgIconPlay className={styles.listItemVideoThumbnailPlayIcon} />
          {videoDurationElement}
          {isVideoReady && videoThumbnailUrl && thumbnailElement}
          {(!isVideoReady || !videoThumbnailUrl) && generatingFeedbackElement}
        </div>
        <div className={styles.listItemNoteMetaContainer}>
          <div className={styles.listItemNote}>
            <NoteWithHashtags
              note={note}
              classNameHashtag={styles.listItemNoteHashtag}
            />
          </div>
          <div className={styles.listItemMetaContainer}>
            {testerMetaElement} {authorMetaElement}{" "}
            {isPublic ? publicMetaElement : null}
          </div>
        </div>
      </Link>
      <ListItemOverflowMenu
        editItemLinkTo={videoTo}
        editItemNavigateHistoryState={{ clipsTabEditId: id }}
        isDownloadDisabled={!isVideoReady || !downloadClipUrl}
        onClickDelete={() => onClickDelete(id, videoId)}
        onClickDownload={() => {
          if (downloadClipUrl) {
            onClickDownload(downloadClipUrl);
          }
        }}
        onClickShare={() => onClickShare(id)}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </div>
  );
}

function ListItemOverflowMenu({
  editItemLinkTo,
  editItemNavigateHistoryState,
  isDownloadDisabled,
  onClickDelete,
  onClickDownload,
  onClickShare,
  onClose,
  onOpen,
}: {
  editItemLinkTo: string;
  editItemNavigateHistoryState: Record<string, unknown>;
  isDownloadDisabled: boolean;
  onClickDelete: () => void;
  onClickDownload: () => void;
  onClickShare: () => void;
  onClose: () => void;
  onOpen: () => void;
}) {
  return (
    <OverflowMenu
      className={styles.listItemOverflowMenu}
      onClose={onClose}
      onOpen={onOpen}
      preventMouseEventDefault
      preventMousePropagation
    >
      <OverflowMenuItem
        linkTo={editItemLinkTo}
        historyState={editItemNavigateHistoryState}
      >
        Edit
      </OverflowMenuItem>
      <OverflowMenuItem onClick={onClickShare}>Share</OverflowMenuItem>
      <OverflowMenuItem onClick={onClickDownload} disabled={isDownloadDisabled}>
        Download
      </OverflowMenuItem>
      <OverflowMenuItem
        className={styles.listItemOverflowMenuItem_destructive}
        onClick={onClickDelete}
      >
        Delete
      </OverflowMenuItem>
    </OverflowMenu>
  );
}
