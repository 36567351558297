import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";
import dayjs from "dayjs";
import startCase from "lodash/startCase";
import { Helmet } from "react-helmet";
import { usePageVisibility } from "react-page-visibility";
import { useDispatch, useSelector } from "react-redux";
import {
  useNavigate,
  useParams,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";

import {
  ButtonLink,
  ButtonPrimary,
  ErrorMessage,
  MainNavigation,
  MainNavigationContent,
  MenuNavigation,
  OverflowMenuItem,
  Pagination,
  TabNavigation,
  Tooltip,
} from "@/design-system";
import { PERSISTENT_STATE_KEY_SHOW_AUTOMATED_INSIGHTS } from "@/helpers-ts";
import { useDefaultOrderQuantity } from "@/hooks/useDefaultOrderQuantity";
import { useFeatures } from "@/hooks/useFeatures";
import { useGenericApiOnSuccess } from "@/hooks/useGenericApiOnSuccess";
import { useHistoryStateAndClear } from "@/hooks/useHistoryStateAndClear";
import { useModal2 } from "@/hooks/useModal2";
import { usePersistentState } from "@/hooks/usePersistentState";
import { DEV_TEST_FILL_REQUEST } from "@/reducers/devTestFill";
import { useAppSelector } from "@/store";
import { Modal2 } from "../../components/Modal";
import { PendingDeliveryListItem } from "../../components/PendingDeliveryListItem";
import Spinner from "../../components/Spinner";
import StopDeliveryModal from "../../components/StopDeliveryModal";
import TaskPreview from "../../components/TaskPreview";
import VideoListItem from "../../components/VideoListItem";
import {
  getLanguageNameByCode,
  getSumFromVideoCount,
  getWordingForTargetingValue,
  getWordingForTestType,
  isDevelopment,
  pluralizer,
  textCantEditTest,
  usePrevious,
} from "../../helpers";
import Loading from "../Loading";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import { stepTargetingByot } from "../OrderTestsModal/steps";
import ScreenersModal from "../ScreenersModal";

import { Clips } from "./Clips";
import { ContentHeaderButtons } from "./ContentHeaderButtons";
import { Insights } from "./Insights";
import { TestResults } from "./TestResults";
import { TestResultsLocked } from "./TestResults/TestResultsLocked";
import { TestTabCardContentEmptyState } from "./TestTabCardContent/EmptyState";
import { TestTabCardContentLocked } from "./TestTabCardContent/Locked";

import styles from "./styles.module.css";

import { ReactComponent as SvgComputerScreenWarn } from "./computer-screen-warn.svg";
import imgEmptyState from "./empty-state.svg";
import imgEmptyStateClips from "./empty-state-clips.svg";
import iconLanguage from "./icon-language.svg";
import iconRepeatOk from "./icon-repeat-ok.svg";
import iconRepeatWarning from "./icon-repeat-warning.svg";
import iconUrl from "./icon-url.svg";
import imgSquircleApp from "./squircle-app.svg";
import imgSquirclePrototype from "./squircle-prototype.svg";
import imgSquircleWebsite from "./squircle-website.svg";
import tabIconClips from "./tab-icon-clips.svg";
import tabIconInsights from "./tab-icon-insights.svg";
import tabIconResults from "./tab-icon-results.svg";
import tabIconTesters from "./tab-icon-testers.svg";
import { ReactComponent as SvgIconShowScreeners } from "./user-magnifying-glass.svg";

export const ReportContext = createContext({
  showAutomatedInsights: true,
});

function sortTestingVideos(testingVideoA, testingVideoB) {
  const statusValues = [
    "started",
    "testing",
    "uploading",
    "pending",
    "processing",
  ];
  const testingVideoAStatusValue = statusValues.indexOf(testingVideoA.status);
  const testingVideoBStatusValue = statusValues.indexOf(testingVideoB.status);
  if (testingVideoAStatusValue === testingVideoBStatusValue) {
    return (
      dayjs(testingVideoB.created_at).valueOf() -
      dayjs(testingVideoA.created_at).valueOf()
    );
  }
  return testingVideoAStatusValue - testingVideoBStatusValue;
}

function getTooltipContentForRepeat(countWaitingFor, delivery) {
  if (delivery.status === "paused") {
    return `This order was paused because you didn’t have enough testers or credits for a complete round.`;
  } else if (countWaitingFor === 0) {
    return `This order is finished for the current round. The next round will start ${dayjs(
      delivery.repeats_at,
    ).from()}.`;
  } else {
    return `This order repeats as long as you have enough testers or credits to perform a complete round of testing.`;
  }
}

function getTargetingSummary(delivery) {
  const region = delivery.targeting?.region;
  const age = delivery.targeting?.age;
  const gender = delivery.targeting?.gender;
  const screenerActive = !!delivery.targeting?.screener_active;
  let device = null;
  if (typeof delivery.ordered_video_count === "object") {
    const foundKey = Object.keys(delivery.ordered_video_count).find(
      (key) => delivery.ordered_video_count[key] > 0,
    );
    if (foundKey !== "any") {
      device = foundKey;
    }
  }

  return [
    ...(region?.map((region) =>
      getWordingForTargetingValue("region", region),
    ) ?? []),
    gender && getWordingForTargetingValue("gender", gender),
    ...(age?.map((age) => getWordingForTargetingValue("age", age)) ?? []),
    screenerActive && "Screener",
    getWordingForTargetingValue("device-alt", device),
  ]
    .filter((i) => !!i)
    .join(" · ");
}

export const testContainerTab = (() => {
  const _values = {
    testers: {
      id: 0,
      label: "Testers",
      icon: tabIconTesters,
      getPageTitle: (pageTitle) => pageTitle,
    },
    clips: {
      id: 1,
      label: "Clips",
      icon: tabIconClips,
      getPageTitle: (pageTitle) => `Clips: ${pageTitle}`,
    },
    report: {
      id: 2,
      label: "Report",
      icon: tabIconResults,
      getPageTitle: (pageTitle) => `Report: ${pageTitle}`,
    },
    insights: {
      id: 3,
      label: "AI Insights",
      icon: tabIconInsights,
      getPageTitle: (pageTitle) => `Insights: ${pageTitle}`,
    },
  };

  return {
    ..._values,
    getById: (tabId) => {
      return Object.values(_values).find((tab) => tab.id === tabId);
    },
    getKeyById: (tabId) => {
      return Object.keys(_values).find((key) => _values[key].id === tabId);
    },
  };
})();

export function Test({ tab }) {
  const revalidator = useRevalidator();
  const isPageVisible = usePageVisibility();
  const prevIsPageVisible = usePrevious(isPageVisible);
  const params = useParams();
  const cantEditTest = useHistoryStateAndClear("cantEditTest");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const testId = Number(params.testId);
  const dispatch = useDispatch();
  const testError = useSelector((state) => state.test.error);
  const test = useSelector((state) => state.test.test);
  const isTestLoading = !!!test;
  const videos = useSelector((state) => state.test.videos);
  const videosTotalCount = useSelector((state) => state.test.videosTotalCount);
  const videosPerPage = useSelector((state) => state.test.videosPerPage);
  const pageFromSearchParams = searchParams.get("page");
  const currentPage =
    pageFromSearchParams === null ? 1 : parseInt(pageFromSearchParams);
  const [stopDeliveryId, setStopDeliveryId] = useState(null);
  const resumeFetching = useSelector((state) => state.delivery.resumeFetching);
  const prevResumeFetching = usePrevious(resumeFetching);
  const resumeDeliveryId = useSelector(
    (state) => state.delivery.resumeDeliveryId,
  );
  const resumeError = useSelector((state) => state.delivery.resumeError);
  const unarchiveFetching = useSelector(
    (state) => state.tests.unarchiveFetching,
  );
  const stopFetching = useSelector((state) => state.delivery.stopFetching);
  const prevUnarchiveFetching = usePrevious(unarchiveFetching);
  const [previewActive, setPreviewActive] = useState();
  const features = useFeatures();
  const defaultOrderQuantity = useDefaultOrderQuantity();
  const [showAutomatedInsights, setShowAutomatedInsights] = usePersistentState(
    PERSISTENT_STATE_KEY_SHOW_AUTOMATED_INSIGHTS,
    true,
  );

  const countTestingVideos = useMemo(
    () =>
      test?.active_deliveries.reduce(
        (acc, cur) => acc + (cur.testing_videos?.length ?? 0),
        0,
      ) ?? 0,
    [test],
  );

  const { showModal: showScreenersModal, modal: screenersModal } = useModal2(
    <ScreenersModal testId={testId} />,
  );

  // If the user opened a test setup page and they has_unfinished_deliveries
  // then they are redirected back here.
  useEffect(() => {
    if (cantEditTest) {
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: textCantEditTest,
      });
    }
  }, [dispatch, cantEditTest]);

  useEffect(() => {
    if (
      prevResumeFetching === true &&
      resumeFetching === false &&
      !resumeError
    ) {
      // Resume did work, reload test
      // dispatch({ type: "TEST_REQUEST", id: testId });
      revalidator.revalidate();
    }
  }, [
    resumeFetching,
    prevResumeFetching,
    resumeDeliveryId,
    resumeError,
    revalidator,
  ]);

  /*  useEffect(() => {
      const query = qs.parse(searchParams);
      if (currentPage > 1) {
        query.page = currentPage;
      } else {
        delete query.page;
      }

    }, [currentPage, searchParams, dispatch]);*/

  /*   useEffect(() => {
    dispatch({
      type: "TEST_VIDEOS_REQUEST",
      testId,
      search: "page=" + currentPage,
    });
  }, [currentPage, testId, dispatch]); */

  useEffect(() => {
    if (isPageVisible) {
      function reloadTestAndVideos() {
        dispatch({ type: "TEST_VIDEOS_RE_REQUEST" });
        dispatch({ type: "TEST_RE_REQUEST" });
      }

      // If page was invisible before, reload immediately as well
      if (prevIsPageVisible === false) {
        reloadTestAndVideos();
      }
      const reloadTestsAndVideosInterval = setInterval(
        reloadTestAndVideos,
        process.env.REACT_APP_REFRESH_INTERVAL,
      );
      return () => {
        clearInterval(reloadTestsAndVideosInterval);
      };
    }
    // currentPage and testId are in the deps array to restart the reload time if a new request is made
  }, [currentPage, testId, isPageVisible, prevIsPageVisible, dispatch]);

  useEffect(() => {
    if (prevUnarchiveFetching === true && unarchiveFetching === false) {
      //dispatch({ type: "TEST_REQUEST", id: testId });
      revalidator.revalidate();
    }
  }, [unarchiveFetching, prevUnarchiveFetching, revalidator]);

  function handleClickEditTest() {
    navigate(`/test/${testId}/setup`);
  }

  function handleClickUnarchive() {
    dispatch({ type: "TEST_UNARCHIVE", id: testId, title: test.title });
  }

  function handleClickGetTesters() {
    dispatch({
      type: "ORDER_TESTERS_INIT_AND_OPEN_MODAL",
      quantity: defaultOrderQuantity,
      testId,
    });
  }

  function handleClickExplorePlans() {
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
    });
  }

  function handleClickPagination(page) {
    if (page > 1) {
      setSearchParams({ ...Object.fromEntries(searchParams.entries()), page });
    } else {
      const { page, ...searchParamsWithoutPage } = Object.fromEntries(
        searchParams.entries(),
      );
      setSearchParams(searchParamsWithoutPage);
    }
  }

  function handleClickStopTestingConfirm() {
    dispatch({
      type: "DELIVERY_STOP_REQUEST",
      deliveryId: stopDeliveryId,
    });
  }

  function handleCloseStopDeliveryModal() {
    setStopDeliveryId(null);
  }

  function handleClickTabTesters() {
    navigate(`/test/${testId}/testers`);
  }

  function handleSelectTab(tabIndex) {
    navigate(`/test/${testId}/${testContainerTab.getKeyById(tabIndex)}`);
  }

  function handleClickShowScreeners() {
    showScreenersModal(true);
  }

  function handleClickShowPreview() {
    setPreviewActive(true);
  }

  function handleClickHidePreview() {
    setPreviewActive(false);
  }

  function handleClickToggleShowAutomatedInsights() {
    setShowAutomatedInsights(!showAutomatedInsights);
  }

  function handleStopDelivery(deliveryId) {
    setStopDeliveryId(deliveryId);
  }

  if (testError && test === null) {
    return <Loading error={testError}>Loading Test</Loading>;
  }

  const hasUnfinishedDeliveries = test?.has_unfinished_deliveries;

  const pageTitle = test?.title
    ? testContainerTab.getById(tab).getPageTitle(test.title)
    : "Loading…";

  const showEmptyStateTesters =
    videos?.length === 0 &&
    test?.invitation?.active === false &&
    test?.active_deliveries.length === 0;

  const tabOptions = [
    {
      value: testContainerTab.insights.id,
      icon: testContainerTab.insights.icon,
      label: testContainerTab.insights.label,
    },
    {
      value: testContainerTab.testers.id,
      icon: testContainerTab.testers.icon,
      label: testContainerTab.testers.label,
      isNotification: test?.new_video_count > 0,
    },
    {
      value: testContainerTab.clips.id,
      icon: testContainerTab.clips.icon,
      label: testContainerTab.clips.label,
      isLocked: features?.clips === false,
    },
    {
      value: testContainerTab.report.id,
      icon: testContainerTab.report.icon,
      label: testContainerTab.report.label,
      isLocked: features?.reports === false,
    },
  ];

  const isShowAiHiddenInfo =
    (tab === testContainerTab.report.id || tab === testContainerTab.clips.id) &&
    features?.ai === true &&
    showAutomatedInsights === false;

  return (
    <>
      <Helmet>
        <title>{`${pageTitle} | Userbrain`}</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <div className={`${styles.content}`}>
            {!isTestLoading && (
              <>
                <div className={styles.contentHeader}>
                  {test.type === "website" && (
                    <img
                      className={styles.testIcon}
                      src={imgSquircleWebsite}
                      alt={"Website"}
                    />
                  )}
                  {test.type === "prototype" && (
                    <img
                      className={styles.testIcon}
                      src={imgSquirclePrototype}
                      alt={"Prototype"}
                    />
                  )}
                  {test.type === "app" && (
                    <img
                      className={styles.testIcon}
                      src={imgSquircleApp}
                      alt={"App"}
                    />
                  )}

                  <h1 className={styles.testTitle} title={test.title}>
                    {test.title}
                  </h1>
                  <div className={styles.testDetails}>
                    <div className={styles.testDetailsItem}>
                      <div>{startCase(getWordingForTestType(test.type))}</div>
                    </div>
                    <div
                      className={cn(
                        styles.testDetailsItem,
                        styles.testDetailsItem_url,
                      )}
                    >
                      <img src={iconUrl} alt="Url" />
                      <div title={test.url}>{test.url}</div>
                    </div>
                    <div className={styles.testDetailsItem}>
                      <img src={iconLanguage} alt="Language" />
                      <div>{getLanguageNameByCode(test.language)}</div>
                    </div>
                  </div>

                  <ContentHeaderButtons
                    onClickUnarchive={handleClickUnarchive}
                    onClickEditTest={handleClickEditTest}
                    onClickShowPreview={handleClickShowPreview}
                    onClickGetTesters={handleClickGetTesters}
                    onClickToggleShowAutomatedInsights={
                      handleClickToggleShowAutomatedInsights
                    }
                    isUnarchiveFetching={unarchiveFetching}
                    hasUnfinishedDeliveries={hasUnfinishedDeliveries}
                    isShowAutomatedInsights={showAutomatedInsights}
                    downloadResultsUrl={test?.download_results}
                    downloadCommentsUrl={test?.download_comments}
                    isTestArchived={test?.archived}
                    isFeatureAi={features?.ai}
                  />
                </div>

                <TabNavigation
                  className={styles.tabNavigation}
                  tabClassName={styles.tabClassName}
                  options={tabOptions}
                  onChange={handleSelectTab}
                  value={tab}
                />

                <MenuNavigation
                  className={styles.menuNavigation}
                  options={tabOptions}
                  onChange={handleSelectTab}
                  value={tab}
                />

                {isShowAiHiddenInfo && (
                  <div className={styles.aiHiddenInfo}>
                    Automated insights hidden in report.{" "}
                    <button onClick={handleClickToggleShowAutomatedInsights}>
                      Show
                    </button>
                  </div>
                )}

                {tab === testContainerTab.insights.id && (
                  <Insights
                    testId={testId}
                    onClickGoToTesters={handleClickTabTesters}
                    onClickShowScreeners={handleClickShowScreeners}
                    onClickGetTesters={handleClickGetTesters}
                    screenerCount={test.screener_count}
                    testHasReport={test.video_count > 0}
                    isSample={Boolean(test.is_sample)}
                  />
                )}

                {tab === testContainerTab.report.id &&
                  features?.reports === true && (
                    <ReportContext.Provider value={{ showAutomatedInsights }}>
                      <TestResults
                        testId={testId}
                        testHasVideos={test.video_count > 0}
                        testHasUnfinishedDeliveries={hasUnfinishedDeliveries}
                      />
                    </ReportContext.Provider>
                  )}

                {tab === testContainerTab.report.id &&
                  features?.reports === false && (
                    <TestResultsLocked
                      onClickExplorePlans={handleClickExplorePlans}
                    />
                  )}

                {tab === testContainerTab.testers.id && (
                  <>
                    <div className={`card ${styles.videos}`}>
                      {videos === null && <Spinner />}

                      {videos !== null && showEmptyStateTesters && (
                        <EmptyStateTesters
                          onClickGetTesters={handleClickGetTesters}
                        />
                      )}

                      {videos !== null && !showEmptyStateTesters && (
                        <>
                          <h2 className={styles.titleTesters}>
                            {test.video_count + countTestingVideos} of{" "}
                            {test.open_video_count + test.video_count} testers
                          </h2>
                          {test.screener_count > 0 && (
                            <button
                              className={styles.showScreeners}
                              onClick={handleClickShowScreeners}
                            >
                              <SvgIconShowScreeners
                                style={{ position: "relative", top: "-2px" }}
                              />{" "}
                              Show {pluralizer("screener")(test.screener_count)}
                            </button>
                          )}
                          <div className={styles.videoList}>
                            {currentPage === 1 && (
                              <ActiveDeliveries
                                test={test}
                                onStopDelivery={handleStopDelivery}
                                resumeDeliveryId={resumeDeliveryId}
                                resumeFetching={resumeFetching}
                                resumeError={resumeError}
                              />
                            )}
                            {/* The videos of the current page */}
                            {videos.map((video) => (
                              <VideoListItem
                                className={styles.videoListItem}
                                type={"video"}
                                openInTestId={testId}
                                video={video}
                                key={video.id}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                    <Pagination
                      className={styles.pagination}
                      currentPage={currentPage}
                      countPages={Math.ceil(videosTotalCount / videosPerPage)}
                      onClickPage={handleClickPagination}
                    />
                  </>
                )}

                {tab === testContainerTab.clips.id &&
                  features?.clips === true && (
                    <TabClips currentTestId={testId} />
                  )}

                {tab === testContainerTab.clips.id &&
                  features?.clips === false && (
                    <ClipsLocked
                      onClickExplorePlans={handleClickExplorePlans}
                    />
                  )}
              </>
            )}
            {isTestLoading && <Spinner />}
          </div>

          <StopDeliveryModal
            isActive={stopDeliveryId !== null}
            onClose={handleCloseStopDeliveryModal}
            onConfirm={handleClickStopTestingConfirm}
            isFetching={stopFetching}
          />

          {screenersModal}

          <Modal2
            isActive={previewActive}
            onClose={handleClickHidePreview}
            content={
              <TaskPreview
                task={test?.task}
                onClickClose={handleClickHidePreview}
              />
            }
          />
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
}

function ActiveDeliveries({
  test,
  onStopDelivery,
  resumeDeliveryId,
  resumeFetching,
  resumeError,
}) {
  const repeatDeliveries = test.active_deliveries.filter(
    (delivery) => delivery.type === "repeat",
  );

  const normalDeliveries = test.active_deliveries.filter(
    (delivery) => delivery.type !== "repeat",
  );

  return (
    <>
      {/* All repeat deliveries */}
      {repeatDeliveries.map((repeatDelivery) => (
        <ActiveRepeatDelivery
          key={repeatDelivery.id}
          delivery={repeatDelivery}
          onStopDelivery={onStopDelivery}
          resumeDeliveryId={resumeDeliveryId}
          resumeFetching={resumeFetching}
          resumeError={resumeError}
        />
      ))}

      {/* BYOT delivery */}
      {test.invitation?.active && <ActiveInvitation testId={test.id} />}

      {/* All active deliveries except repeat */}
      {normalDeliveries.map((delivery) => (
        <ActiveDelivery
          key={delivery.id}
          testId={test.id}
          delivery={delivery}
          onStopDelivery={onStopDelivery}
        />
      ))}

      {/* All testing videos from all active deliveries */}
      {test.active_deliveries
        .map((delivery) => delivery.testing_videos)
        .flat()
        .sort(sortTestingVideos)
        .map((testingVideo, index) => (
          <VideoListItem
            key={index}
            className={styles.videoListItem}
            type={"testingVideo"}
            video={testingVideo}
          />
        ))}
    </>
  );
}

function ActiveRepeatDelivery({
  delivery,
  onStopDelivery,
  resumeDeliveryId,
  resumeFetching,
  resumeError,
}) {
  const dispatch = useDispatch();

  const countOrderedVideos = getSumFromVideoCount(delivery.ordered_video_count);
  const countTestingVideos = delivery.testing_videos.length;
  const countDeliveredVideos = getSumFromVideoCount(
    delivery.delivered_video_count,
  );
  const countWaitingFor =
    countOrderedVideos - countDeliveredVideos - countTestingVideos;

  const targetingSummary = getTargetingSummary(delivery);

  function handleClickResumeTesting() {
    dispatch({
      type: "DELIVERY_RESUME_REQUEST",
      deliveryId: delivery.id,
    });
  }

  function handleClickStopTesting() {
    onStopDelivery(delivery.id);
  }

  const resumeFetchingThisDelivery =
    resumeDeliveryId === delivery.id && resumeFetching;
  const resumeErrorThisDelivery =
    resumeDeliveryId === delivery.id && resumeError;

  let repeatOrderTitle;
  let repeatOrderTitle2ndPartThatBreaksWithTooltip;
  if (delivery.repeat_period === "weekly") {
    repeatOrderTitle = `Automatically orders ${countOrderedVideos} new testers every `;
    repeatOrderTitle2ndPartThatBreaksWithTooltip = dayjs(
      delivery.repeats_at,
    ).format("dddd [at] h:mm a");
  } else if (delivery.repeat_period === "biweekly") {
    repeatOrderTitle = `Automatically orders ${countOrderedVideos} new testers every other `;
    repeatOrderTitle2ndPartThatBreaksWithTooltip = dayjs(
      delivery.repeats_at,
    ).format("dddd [at] h:mm a");
  } else if (delivery.repeat_period === "monthly") {
    repeatOrderTitle = `Automatically orders ${countOrderedVideos} new testers every month. The next order will be on `;
    repeatOrderTitle2ndPartThatBreaksWithTooltip = dayjs(
      delivery.repeats_at,
    ).format("ddd, MMM D, YYYY h:mm a");
  }

  const screeningText =
    delivery.screening_count > 0
      ? ` (${pluralizer("tester")(delivery.screening_count, true)} screening)`
      : "";
  const pendingDeliveryListItemText = `Waiting for ${pluralizer("tester")(
    countWaitingFor,
    true,
  )} from repeat delivery${screeningText}…`;

  return (
    <>
      <div
        className={cn(
          styles.repeatOrder,
          delivery.status === "paused" && styles.repeatOrder_paused,
        )}
      >
        <div className={styles.repeatOrderInner}>
          <img
            className={styles.repeatOrderIcon}
            src={
              delivery.status === "paused" ? iconRepeatWarning : iconRepeatOk
            }
            alt="Repeats automatically"
          />

          <div className={styles.repeatOrderContent}>
            <div className={styles.repeatOrderTitle}>
              {repeatOrderTitle}
              <span style={{ whiteSpace: "nowrap" }}>
                {repeatOrderTitle2ndPartThatBreaksWithTooltip}
                &nbsp;
                <Tooltip
                  content={getTooltipContentForRepeat(
                    countWaitingFor,
                    delivery,
                  )}
                />
              </span>
            </div>
            <div className={styles.repeatOrderTargetingSummary}>
              {targetingSummary}
            </div>

            {delivery.status === "paused" && (
              <div className={styles.repeatOrderPauseMessage}>
                Your {delivery.repeat_period} order has been paused. Please make
                sure that you have enough credits and resume the repeating
                order.
              </div>
            )}

            {resumeErrorThisDelivery && (
              <ErrorMessage className={styles.repeatOrderErrorMessage} nomargin>
                {resumeErrorThisDelivery.message}
              </ErrorMessage>
            )}

            {delivery.status === "paused" && (
              <div className={styles.repeatOrderPauseControl}>
                <ButtonPrimary onClick={handleClickResumeTesting}>
                  {resumeFetchingThisDelivery
                    ? "Resuming…"
                    : "Resume repeating order"}
                </ButtonPrimary>
                <ButtonLink
                  className={styles.repeatOrderCancel}
                  onClick={handleClickStopTesting}
                >
                  Cancel repeating order
                </ButtonLink>
              </div>
            )}
          </div>
          {delivery.status !== "paused" && (
            <ButtonLink
              className={styles.repeatOrderCancel}
              onClick={handleClickStopTesting}
            >
              Cancel repeating order
            </ButtonLink>
          )}
        </div>
      </div>

      {countWaitingFor > 0 && (
        <PendingDeliveryListItem
          className={styles.pendingDeliveryListItem}
          text={pendingDeliveryListItemText}
          targetingSummary={targetingSummary}
        />
      )}
    </>
  );
}

function ActiveDelivery({ delivery, onStopDelivery, testId }) {
  const dispatch = useDispatch();
  const countOrderedVideos = getSumFromVideoCount(delivery.ordered_video_count);
  const countTestingVideos = delivery.testing_videos.length;
  const countDeliveredVideos = getSumFromVideoCount(
    delivery.delivered_video_count,
  );
  const countWaitingFor =
    countOrderedVideos - countDeliveredVideos - countTestingVideos;

  const targetingSummary = getTargetingSummary(delivery);

  function handleClickStopTesting() {
    onStopDelivery(delivery.id);
  }

  const handleApiOnSuccess = useCallback(() => {
    dispatch({ type: "TEST_VIDEOS_RE_REQUEST" });
    dispatch({ type: "TEST_RE_REQUEST" });
  }, [dispatch]);

  useGenericApiOnSuccess("devTestFill", handleApiOnSuccess);

  const screeningText =
    delivery.screening_count > 0
      ? ` (${pluralizer("tester")(delivery.screening_count, true)} screening)`
      : "";

  let text = `Waiting for ${pluralizer("tester")(
    countWaitingFor,
    true,
  )}${screeningText}…`;

  if (countWaitingFor > 0) {
    return (
      <PendingDeliveryListItem
        className={styles.pendingDeliveryListItem}
        key={delivery.id}
        text={text}
        targetingSummary={targetingSummary}
        overflowMenuItems={
          <>
            <OverflowMenuItem
              icon={solid("times")}
              onClick={handleClickStopTesting}
            >
              Stop testing
            </OverflowMenuItem>
            {isDevelopment && (
              <OverflowMenuItem
                icon={regular("user-plus")}
                onClick={() => {
                  dispatch({
                    type: DEV_TEST_FILL_REQUEST,
                    testId,
                  });
                }}
              >
                Fill random delivery
              </OverflowMenuItem>
            )}
          </>
        }
      />
    );
  } else {
    return null;
  }
}

function ActiveInvitation({ testId }) {
  const dispatch = useDispatch();
  const defaultOrderQuantity = useDefaultOrderQuantity();
  function handleClickManageLink() {
    dispatch({
      type: "ORDER_TESTERS_INIT_AND_OPEN_MODAL",
      testId,
      quantity: defaultOrderQuantity,
      initialStep: stepTargetingByot,
    });
  }
  return (
    <PendingDeliveryListItem
      className={styles.pendingDeliveryListItem}
      key={"byot"}
      text={"Waiting for your own testers…"}
      overflowMenuItems={
        <OverflowMenuItem
          icon={regular("link")}
          onClick={handleClickManageLink}
        >
          Manage link
        </OverflowMenuItem>
      }
    />
  );
}

function EmptyStateTesters(props) {
  const { onClickGetTesters } = props;

  return (
    <TestTabCardContentEmptyState
      imageSrc={imgEmptyState}
      imageAlt={"Get testers"}
      title={"Continue by getting your first testers."}
      content={
        <ButtonPrimary
          className={styles.emptyStateButton}
          onClick={onClickGetTesters}
        >
          <FontAwesomeIcon icon={regular("user-plus")} /> Get testers
        </ButtonPrimary>
      }
    />
  );
}

function TabClips({ currentTestId }) {
  const { isError, error, isFetching } = useAppSelector(
    (state) => state.testClipsApi,
  );
  const testClipsReducer = useAppSelector((state) => state.testClips);

  const isClipsForThisTestLoaded =
    Number(testClipsReducer.testId) === currentTestId;
  const isTestHasClips = Boolean(testClipsReducer.unfilteredTotal);

  let content;

  if (isError) {
    content = <CardFetchingError error={error} />;
  } else if (isClipsForThisTestLoaded && isTestHasClips) {
    content = <Clips {...testClipsReducer} isFetching={isFetching} />;
  } else if (isFetching) {
    content = <Spinner />;
  } else if (isClipsForThisTestLoaded && !isTestHasClips) {
    content = <EmptyStateClips />;
  }

  return <CardClips>{content}</CardClips>;
}

function CardClips({ children }) {
  return <div className={`card ${styles.tabClips}`}>{children}</div>;
}

function CardFetchingError({ error }) {
  return (
    <div className={styles.cardFetchingError}>
      <SvgComputerScreenWarn />
      <div className={styles.cardFetchingErrorMessage}>
        Oops! We encountered an error while loading the clips. Please try again.
        If the issue persists, contact our{" "}
        <a href={"mailto:support@userbrain.com"}>support team</a> for
        assistance.
      </div>
      <div className={styles.cardFetchingErrorMessageSub}>
        {error.status ? `Status: ${error.status}, ` : ""}
        {error.message}
      </div>
    </div>
  );
}

function EmptyStateClips() {
  return (
    <TestTabCardContentEmptyState
      className={styles.testTabCard}
      imageSrc={imgEmptyStateClips}
      imageAlt={"Your clips"}
      title={
        "Here you will find a list of all clips you have created for your user tests."
      }
    />
  );
}

function ClipsLocked({ onClickExplorePlans }) {
  return (
    <CardClips>
      <TestTabCardContentLocked
        imageSrc={imgEmptyStateClips}
        title={"Want clips?"}
        text={
          <>
            Create clips from videos to share with your team and stakeholders.
          </>
        }
        onClickExplorePlans={onClickExplorePlans}
      />
    </CardClips>
  );
}
