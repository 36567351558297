import { useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { cn } from "@sys42/utils";
import { Link } from "react-router-dom";

import { FormFieldTermsAndConditions } from "@/components/FormFieldTermsAndConditions";
import {
  Button,
  ButtonPrimary,
  DropDown,
  DropDownItem,
  ErrorMessage,
  FieldHelp,
  FormField,
  InputPassword,
  InputText,
  Stack,
} from "@/design-system";
import { LayoutCenterWithLogo } from "@/layouts/LayoutCenterWithLogo";
import { useAppDispatch, useAppSelector } from "@/store";
import FeatureList, { FeatureListItem } from "../../../components/FeatureList";
import {
  advertedPoolSize,
  placeholderEmail,
  shuffleArray,
} from "../../../helpers";

import Brands from "./Brands";
import CustomerTesterSwitch from "./CustomerTesterSwitch";

import styles from "./styles.module.css";

import imageFreePlan from "./free-plan.png";

const referralOptions = [
  { label: "Search Engine (Google, Bing, etc.)", value: "search" },
  { label: "Podcast", value: "podcast" },
  { label: "Social Media", value: "social" },
  { label: "Blog", value: "blog" },
  { label: "Conference", value: "conference" },
  { label: "Friend or Colleague", value: "friend" },
];
shuffleArray(referralOptions);
referralOptions.push({ label: "Other", value: "other" });

const becomeATesterURL = "https://tester.userbrain.com/";

export function RegistrationForm({
  email,
  password,
  onChangeEmail,
  onChangePassword,
  plan,
}: {
  email: string;
  password: string;
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
  plan?: string;
}) {
  const dispatch = useAppDispatch();

  const isFreePlanChosen = plan === "free";

  const [showConfirmNotATester, setShowConfirmNotATester] = useState(false);
  const [confirmedNotATester, setConfirmedNotATester] = useState(false);
  const [howDidYouHearAboutUs, setHowDidYouHearAboutUs] = useState<
    string | null
  >(null);
  const [howDidYouHearAboutUsOther, setHowDidYouHearAboutUsOther] =
    useState("");
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // When the sinup was a success, the user is automatically signed in.
  // we also need to wait for the sign in to be completed.
  const bussySigningUpOrIn = useAppSelector(
    (state) => state.user.signingUp || state.user.signingIn,
  );

  const signUpError = useAppSelector((state) => state.user.signUpError);
  const signUpErrorMessage = signUpError?.message;
  const signUpFieldFeedback = signUpError?.fieldFeedback;

  function register() {
    const sourceSelfReported =
      howDidYouHearAboutUs === "other"
        ? howDidYouHearAboutUsOther
        : howDidYouHearAboutUs;
    dispatch({
      type: "SIGN_UP",
      email,
      password,
      sourceSelfReported,
      isTermsAccepted,
      plan,
    });
  }

  function handleClickNotATester() {
    setConfirmedNotATester(true);
    setShowConfirmNotATester(false);
    register();
  }

  function handleClickBecomeATester() {
    window.location.href =
      becomeATesterURL + `?email=${encodeURIComponent(email)}`;
  }

  function handleClickRegister() {
    if (!confirmedNotATester) {
      setShowConfirmNotATester(true);
    } else {
      register();
    }
  }

  if (showConfirmNotATester) {
    return (
      <LayoutCenterWithLogo>
        <CustomerTesterSwitch
          howDidYouHearAboutUs={howDidYouHearAboutUs}
          onChooseCustomer={handleClickNotATester}
          onChooseTester={handleClickBecomeATester}
          plan={plan}
        />
      </LayoutCenterWithLogo>
    );
  } else {
    return (
      <LayoutCenterWithLogo
        classNameContainer={cn(
          isFreePlanChosen && styles.layoutCenterWithLogo_freePlan,
        )}
        endComponent={
          <div className={styles.haveAnAccountOutsideRegistrationBox}>
            <AlreadyHaveAccountButton />
          </div>
        }
      >
        <div className={styles.registrationContainer}>
          {isFreePlanChosen && (
            <div className={styles.freePlan}>
              <img
                width={160}
                src={imageFreePlan}
                alt="Free Plan"
                className={styles.freePlanImage}
              />
              <h1 className={styles.freePlanTitle}>
                Say hello to
                <br />
                <em>free</em> user testing
              </h1>
              <div className={styles.freePlanSubtitle}>
                Set up your free Userbrain account.
              </div>
            </div>
          )}

          {!isFreePlanChosen && (
            <>
              <h1 className={styles.startFreeTrialTitle}>
                Start your <em>free trial.</em>
              </h1>
              <div className={styles.allFreeTrialsInclude}>
                <h2 className={styles.allFreeTrialsIncludeTitle}>
                  Your free trial includes:
                </h2>
                <FeatureList className={styles.featureList}>
                  <FeatureListItem>
                    2 testers from our {advertedPoolSize} pool of qualified
                    testers
                  </FeatureListItem>
                  <FeatureListItem>
                    5 sessions with your own testers
                  </FeatureListItem>
                  <FeatureListItem>
                    20 admins &amp; 50 collaborators
                  </FeatureListItem>
                  <FeatureListItem>All features</FeatureListItem>
                  <FeatureListItem>No credit card required</FeatureListItem>
                </FeatureList>
              </div>
            </>
          )}

          <div className={styles.registrationBoxContainer}>
            <div className={`${styles.form} ${styles.registrationBox} card`}>
              <Stack>
                {signUpErrorMessage && (
                  <ErrorMessage
                    icon={regular("ban")}
                    message={signUpErrorMessage}
                  />
                )}
                <FormField
                  label={"Your email"}
                  errorMessage={signUpFieldFeedback?.email}
                >
                  <InputText
                    type={"email"}
                    autoComplete={"email"}
                    placeholder={placeholderEmail}
                    onChange={onChangeEmail}
                    value={email}
                    fullWidth
                    autoFocus={!email}
                  />
                </FormField>

                <FormField
                  label={"Set a password"}
                  errorMessage={signUpFieldFeedback?.password}
                >
                  <InputPassword
                    autoComplete={"new-password"}
                    onChange={onChangePassword}
                    value={password}
                    withShowPasswordToggle={true}
                    autoFocus={!!email && !password}
                    fullWidth
                  />
                  <FieldHelp>At least 8 characters</FieldHelp>
                </FormField>

                <FormField
                  errorMessage={signUpFieldFeedback?.howDidYouHearAboutUs}
                >
                  <DropDown
                    value={howDidYouHearAboutUs}
                    onChange={(e: { target: { value: string } }) =>
                      setHowDidYouHearAboutUs(e.target.value)
                    }
                    placeholder={"How did you hear about us? (optional)"}
                    fullWidth
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {referralOptions.map(({ label, value }) => (
                      <DropDownItem key={value} value={value}>
                        {label}
                      </DropDownItem>
                    ))}
                  </DropDown>
                </FormField>

                {howDidYouHearAboutUs === "other" && (
                  <FormField>
                    <InputText
                      type={"text"}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setHowDidYouHearAboutUsOther(e.target.value)
                      }
                      value={howDidYouHearAboutUsOther}
                      fullWidth
                      placeholder={"How did you hear about us?"}
                      autoFocus={!howDidYouHearAboutUsOther}
                    />
                  </FormField>
                )}

                <FormFieldTermsAndConditions
                  errorMessage={signUpFieldFeedback?.terms_accepted}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsTermsAccepted(e.target.checked)
                  }
                  checked={isTermsAccepted}
                />

                <ButtonPrimary
                  fullWidth
                  className={styles.buttonPromo}
                  disabled={bussySigningUpOrIn}
                  onClick={handleClickRegister}
                >
                  {bussySigningUpOrIn
                    ? "Creating account…"
                    : isFreePlanChosen
                      ? "Create account"
                      : "Start your free trial now"}
                </ButtonPrimary>
              </Stack>
              <div className={styles.haveAnAccountInRegistrationBox}>
                <AlreadyHaveAccountButton />
              </div>
            </div>
          </div>
        </div>
        <Brands />
      </LayoutCenterWithLogo>
    );
  }
}

function AlreadyHaveAccountButton() {
  return (
    <div className={styles.haveAnAccountAndButton}>
      <div className={styles.haveAnAccount}>Already have an account?</div>
      <Button customTag={Link} to={"/login"}>
        Login
      </Button>
    </div>
  );
}
