import { useEffect } from "react";
import isFunction from "lodash/isFunction";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  LoaderFunctionArgs,
  Navigate,
  NavigateProps,
  Outlet,
  redirect,
  Route,
  ShouldRevalidateFunctionArgs,
  useLocation,
  useParams,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";

import ModalWelcomeFreeTrial from "@/components/ModalWelcomeFreeTrial";
import ScrollToTop from "@/components/ScrollToTop";
import Account from "@/containers/Account";
import AccountBilling from "@/containers/AccountBilling";
import AccountBuyCredits from "@/containers/AccountBuyCredits";
import AccountDev from "@/containers/AccountDev";
import AccountSuperAdmin from "@/containers/AccountSuperAdmin";
import AccountTeam from "@/containers/AccountTeam";
import { ConfirmEmail } from "@/containers/ConfirmEmail";
import { GlobalModals } from "@/containers/GlobalModals";
import Loading from "@/containers/Loading";
import NewPassword from "@/containers/NewPassword";
import OrderTestsModal from "@/containers/OrderTestsModal";
import ReleaseNotes from "@/containers/ReleaseNotes";
import { SharedClip } from "@/containers/SharedClip";
import { Test, testContainerTab } from "@/containers/Test";
import TestSetup from "@/containers/TestSetup";
import VerifyEmail from "@/containers/VerifyEmail";
import Video from "@/containers/Video";
import SharedVideo from "@/containers/Video/Shared";
import Videos from "@/containers/Videos";
import { PreviousLocationContextProvider } from "@/context/PreviousLocation";
import { DesignSystemContainer } from "@/design-system";
import { allowedTestTypes, SHOW_DEV_TOOLS, usePrevious } from "@/helpers";
import { PERSISTENT_STATE_KEY_SHOW_AUTOMATED_INSIGHTS } from "@/helpers-ts";
import { getStoredPersistentStateValue } from "@/hooks/usePersistentState";
import { selectIsAuth } from "@/selectors/global";
import {
  selectEmailVerificationRequired,
  selectIsUserLoaded,
  selectIsUserOwnerOrAdmin,
} from "@/selectors/user";
import { store, useAppSelector } from "@/store";
import Login from "../containers/Login";
import Tests from "../containers/Tests";

import authenticatedRouteLoader from "./loaders/authenticatedRoute";
import confirmEmailLoader from "./loaders/confirmEmail";
// Loaders
import dashboardLoader from "./loaders/dashboard";
import sharedClipLoader from "./loaders/sharedClip";
import sharedVideoLoader from "./loaders/sharedVideo";
import testLoader, {
  shouldRevalidate as testLoaderShouldRevalidate,
} from "./loaders/test";
import testClipsLoader from "./loaders/testClips";
import testInsightsLoader from "./loaders/testInsights";
import testReportLoader from "./loaders/testReport";
import testTestersLoader from "./loaders/testTesters";
import videoLoader from "./loaders/video";
import videosLoader from "./loaders/videos";

export function createRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route element={<App />}>
        <Route path="/" />
        {noAuthRequiredRoutes}
        {notAuthenticatedRoutes}
        {authenticatedRoutes}
      </Route>,
    ),
  );
}

function Head() {
  const titleTemplate =
    process.env.REACT_APP_HOST_ENV === "development" ? "DEV %s" : "%s";
  return (
    <Helmet titleTemplate={titleTemplate}>
      <title>Userbrain Dashboard</title>
    </Helmet>
  );
}

function Global() {
  const dispatch = useDispatch();

  const orderTestersModalActive = useAppSelector(
    (state) => state.orderTesters.modalActive,
  );

  const signedIn = useAppSelector((state) => state.user.signedIn);

  const welcomeMessage = useAppSelector((state) => state.welcomeMessage);

  const handleCloseOrderTestsModal = () => {
    dispatch({ type: "ORDER_TESTERS_CLOSE_MODAL" });
  };

  const handleCloseWelcomeMessage = () => {
    dispatch({ type: "WELCOME_MESSAGE_HIDE" });
  };

  const handleClickCreateYourFirstTestNow = () => {
    dispatch({ type: "WELCOME_MESSAGE_HIDE" });
    dispatch({ type: "GLOBAL_MODAL_OPEN", modal: "createTest" });
  };

  useEffect(() => {
    function handlePopState() {
      // Close order testers modal when browser navigation occurs
      dispatch({ type: "ORDER_TESTERS_CLOSE_MODAL" });
    }
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dispatch]);

  return (
    <>
      <OrderTestsModal
        onClose={handleCloseOrderTestsModal}
        isActive={orderTestersModalActive}
      />
      <ModalWelcomeFreeTrial
        onClose={handleCloseWelcomeMessage}
        onClickCreateYourFirstTestNow={handleClickCreateYourFirstTestNow}
        onClickStartExploring={handleCloseWelcomeMessage}
        isActive={welcomeMessage.active && signedIn}
        welcomeMessageType={welcomeMessage.type ?? "freetrial"}
      />
      <GlobalModals />
    </>
  );
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  // When the window is focused, we dispatch an action to react to it
  useEffect(() => {
    function handleWindowFocus() {
      dispatch({ type: "WINDOW_FOCUS" });
    }
    window.addEventListener("focus", handleWindowFocus);
    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [dispatch]);

  // Enable tracking when the user is authenticated
  const isAuth = useAppSelector(selectIsAuth);
  useEffect(() => {
    if (isAuth) {
      if (isFunction(window.enableTracking)) {
        window.enableTracking(); // noop after one call
      }
    }
  }, [isAuth]);

  if (location.pathname === "/") {
    if (isAuth === true) {
      return <Navigate to="/dashboard" replace />;
    } else {
      return <Navigate to="/login" replace />;
    }
  }

  return (
    <Providers>
      <DesignSystemContainer>
        <Head />
        <Outlet />
        <Global />
      </DesignSystemContainer>
    </Providers>
  );
}

function AuthenticatedRoute() {
  const isAuth = useAppSelector(selectIsAuth);
  const prevIsAuth = usePrevious(isAuth);
  const emailVerificationRequired = useAppSelector(
    selectEmailVerificationRequired,
  );
  const prevEmailVerificationRequired = usePrevious(emailVerificationRequired);

  const revalidator = useRevalidator();
  const location = useLocation();

  const isUserLoaded = useAppSelector(selectIsUserLoaded);
  const userError = useAppSelector((state) => state.user.userError);
  const userIsError = useAppSelector((state) => state.user.userIsError);
  const userFetching = useAppSelector((state) => state.user.userFetching);

  useEffect(() => {
    // When the user is already authenticated, isAuth should be true
    // from the beginning.
    // This hook should only get triggered when the user logs in.
    if (isAuth === true && prevIsAuth === false) {
      revalidator.revalidate();
    }
  }, [isAuth, prevIsAuth, revalidator]);

  // Revalidate when the email verification status changes to verified
  useEffect(() => {
    if (
      emailVerificationRequired === false &&
      prevEmailVerificationRequired === true
    ) {
      revalidator.revalidate();
    }
  }, [emailVerificationRequired, prevEmailVerificationRequired, revalidator]);

  if (isAuth === false) {
    if (location.pathname === "/dashboard") {
      return <Navigate to="/login" />;
    } else {
      return (
        <Navigate
          to={`/login?redirectTo=${location.pathname + location.search}`}
        />
      );
    }
  } else if ((!isUserLoaded && userFetching === true) || userIsError === true) {
    return <Loading error={userError}>Loading</Loading>;
  } else if (emailVerificationRequired) {
    return <VerifyEmail />;
  } else {
    return <Outlet />;
  }
}

function AccountOwnerRoute() {
  const isOwnerOrAdmin = useAppSelector(selectIsUserOwnerOrAdmin);
  if (isOwnerOrAdmin === true) {
    return <Outlet />;
  } else {
    return <Navigate to="/account" replace />;
  }
}

function NotAuthenticatedRoute() {
  const isAuth = useAppSelector(selectIsAuth);
  const [searchParams] = useSearchParams();
  const signInRedirectTo = useAppSelector(
    (state) => state.user.signInRedirectTo,
  );
  if (isAuth === true) {
    const navigateTo =
      signInRedirectTo ||
      searchParams.get("redirectTo") || // Fallback to redirectTo query param if set
      "/dashboard";
    return <Navigate to={navigateTo} replace />;
  } else {
    return <Outlet />;
  }
}

const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <PreviousLocationContextProvider>
      <ScrollToTop>{children}</ScrollToTop>
    </PreviousLocationContextProvider>
  );
};

const accountOwnerRoutes = (
  <Route element={<AccountOwnerRoute />}>
    <Route path="/account/billing" element={<AccountBilling />} />
    <Route path="/account/buy-credits" element={<AccountBuyCredits />} />
    <Route path="/account/team" element={<AccountTeam />} />
  </Route>
);

const authenticatedRoutes = (
  <Route
    element={<AuthenticatedRoute />}
    loader={authenticatedRouteLoader}
    shouldRevalidate={shouldAuthenticatedRouteRevalidate}
  >
    {accountOwnerRoutes}
    <Route path="/dashboard" element={<Tests />} loader={dashboardLoader} />
    <Route
      path="/create-test-from-template/:templateId"
      element={
        <NavigateWithParams
          to={`/dashboard`}
          replace
          state={({ templateId }: { templateId: string }) => ({ templateId })}
        />
      }
    />
    <Route path="/release-notes" element={<ReleaseNotes />} />
    <Route
      path="/test/:testId/video/:videoId"
      element={
        <NavigateWithParams
          to={({ videoId }: any) => `/video/${videoId}`}
          replace
        />
      }
    />
    <Route path="/video/:id" element={<Video />} loader={videoLoader} />
    <Route path="/super-admin" element={<AccountSuperAdmin />} />
    <Route path="/test/duplicate/:duplicateId" element={<TestSetup />} />
    <Route path="/test/create" element={<Navigate to="/dashboard" replace />} />
    {allowedTestTypes.map((type) => (
      <Route
        key={type}
        path={`/test/create/${type}/template/:templateId`}
        element={<TestSetup testType={type} />}
      />
    ))}
    {allowedTestTypes.map((type) => (
      <Route
        key={type}
        path={`/test/create/${type}`}
        element={<TestSetup testType={type} />}
      />
    ))}
    <Route path="/test/:id/setup/:step" element={<TestSetup />} />
    <Route path="/test/:id/setup" element={<TestSetup />} />
    <Route loader={testLoader} shouldRevalidate={testLoaderShouldRevalidate}>
      <Route
        path="/test/:testId"
        element={
          <NavigateWithParams
            to={({ testId }: any) => `/test/${testId}/insights`}
            state={(params: any, state: any) => state}
            replace
          />
        }
      />
      <Route
        path="/test/:testId/testers"
        element={<Test tab={testContainerTab.testers.id} />}
        loader={testTestersLoader}
      />
      <Route
        path="/test/:testId/report"
        element={<Test tab={testContainerTab.report.id} />}
        loader={testReportLoader}
      />
      <Route
        path="/test/:testId/insights"
        element={<Test tab={testContainerTab.insights.id} />}
        loader={testInsightsLoader}
      />
      <Route
        path="/test/:testId/clips"
        element={<Test tab={testContainerTab.clips.id} />}
        loader={conditionalTestClipsLoader}
      />
    </Route>
    <Route
      path="/test/:testId/report/video/:videoId"
      element={
        <NavigateWithParams
          to={({ videoId }: any) => `/video/${videoId}`}
          replace
        />
      }
    />
    <Route path="/sessions" element={<Videos />} loader={videosLoader} />
    <Route
      path="/test/:id/results"
      element={
        <NavigateWithParams
          to={({ id }: any) => `/test/${id}/report`}
          replace
        />
      }
    />
    <Route
      path="/account/billing-information"
      element={<Navigate to="/account/billing" replace />}
    />
    <Route
      path="/account/subscription"
      element={<Navigate to="/account/billing" replace />}
    />
    <Route
      path="/account/invoices"
      element={<Navigate to="/account/billing" replace />}
    />
    <Route
      path="/account/credits-and-subscription"
      element={<Navigate to="/account/buy-credits" replace />}
    />
    <Route path="/account" element={<Account />} />
  </Route>
);

const notAuthenticatedRoutes = (
  <Route element={<NotAuthenticatedRoute />}>
    <Route path="/login/:email" element={<Login />} />
    <Route path="/login" element={<Login />} />
    <Route path="/reset-password" element={<Login form={"reset-password"} />} />
    <Route path="/register/:email" element={<Login form={"register"} />} />
    <Route path="/register" element={<Login form={"register"} />} />
    {/* <Route path="*" element={<Login />} /> */}
  </Route>
);

const noAuthRequiredRoutes = (
  <>
    <Route
      path="/confirm/:token"
      element={<ConfirmEmail />}
      loader={confirmEmailLoader}
    />
    <Route
      path="/shared/:hash"
      element={<SharedVideo />}
      loader={sharedVideoLoader}
    />
    <Route
      path="/shared/clips/:hash"
      element={<SharedClip />}
      loader={sharedClipLoader}
    />
    <Route path="/new-password/:token/:email" element={<NewPassword />} />
    <Route path="/new-password/:token" element={<NewPassword />} />
    <Route path="/new-password" element={<NewPassword />} />
    <Route path="/invitation/:token" element={<Login form={"invitation"} />} />
    {SHOW_DEV_TOOLS && <Route path="/account/dev" element={<AccountDev />} />}
  </>
);

// This is like <Navigate>, but the params are available as a parameter if to or state is a function
function NavigateWithParams({
  to,
  state,
  ...rest
}: Omit<NavigateProps, "to" | "state"> & {
  to:
    | NavigateProps["to"]
    | ((params: ReturnType<typeof useParams>) => NavigateProps["to"]);
  state?:
    | NavigateProps["state"]
    | ((
        params: ReturnType<typeof useParams>,
        state: any,
      ) => NavigateProps["state"]);
}) {
  const params = useParams();
  const location = useLocation();
  let toValue, stateValue;
  if (typeof to === "function") {
    toValue = to(params);
  } else {
    toValue = to;
  }
  if (typeof state === "function") {
    stateValue = state(params, location.state);
  } else {
    stateValue = state;
  }
  return <Navigate to={toValue} state={stateValue} {...rest} />;
}

function shouldAuthenticatedRouteRevalidate(
  args: ShouldRevalidateFunctionArgs,
) {
  const isUserLoaded = selectIsUserLoaded(store.getState());
  // Revaildate when the user is not loaded
  return !isUserLoaded;
}

function conditionalTestClipsLoader(args: LoaderFunctionArgs) {
  const storedValueShowAutomatedInsightsClips = getStoredPersistentStateValue(
    PERSISTENT_STATE_KEY_SHOW_AUTOMATED_INSIGHTS,
  );

  const url = new URL(args.request.url);
  url.searchParams.has("show_ai");

  // Set show_ai to false if the stored value is false and the query param is not set.
  if (
    storedValueShowAutomatedInsightsClips === false &&
    url.searchParams.get("show_ai") !== "false"
  ) {
    url.searchParams.set("show_ai", "false");
    return redirect(`${url.pathname}${url.search}`);
  }

  // Remove show_ai query param if the stored value is not false and the query param is set.
  if (
    storedValueShowAutomatedInsightsClips !== false &&
    url.searchParams.has("show_ai")
  ) {
    url.searchParams.delete("show_ai");
    return redirect(`${url.pathname}${url.search}`);
  }

  // Otherwise, proceed with the original loader.
  return testClipsLoader(args);
}
