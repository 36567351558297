import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { cn } from "@sys42/utils";
import isUndefined from "lodash/isUndefined";
import { useSearchParams } from "react-router-dom";

import { VideoClipTagFilterItem } from "@/entities/video/clipTagFilterItem";
import { VideoClipTaskFilterItem } from "@/entities/video/clipTaskFilterItem";
import { VideoClipTesterFilterItem } from "@/entities/video/clipTesterFilterItem";
import { PERSISTENT_STATE_KEY_SHOW_AUTOMATED_INSIGHTS } from "@/helpers-ts";
import { usePersistentState } from "@/hooks/usePersistentState";
import { TEST_CLIPS_PARAM_NAME } from "@/routes/loaders/testClips";

import { Dropdown } from "./Dropdown";
import { FilterOptionCategory } from "./types";
import { toggleArrayItem } from "./utils";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClear } from "../icons/icon-clear.svg";
import { ReactComponent as SvgIconStar } from "../icons/icon-star.svg";
import { ReactComponent as SvgIconTag } from "../icons/icon-tab-tag.svg";
import { ReactComponent as SvgIconTask } from "../icons/icon-tab-task.svg";
import { ReactComponent as SvgIconTester } from "../icons/icon-tab-tester.svg";

type FiltersProps = {
  className: string;
  tasks: {
    options: VideoClipTaskFilterItem[] | null;
    active: string[] | null;
  };
  testers: {
    options: VideoClipTesterFilterItem[] | null;
    active: string[] | null;
  };
  tags: {
    options: VideoClipTagFilterItem[] | null;
    active: string[] | null;
    isTaglessNotesSelected: boolean;
    isTaglessNotesCount: number;
  };
};

/**
 * Filters
 */
export function Filters({ testers, tasks, tags, className }: FiltersProps) {
  const [openCategory, setOpenCategory] = useState<FilterOptionCategory | null>(
    null,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const [localActiveTesters, setLocalActiveTesters] = useState<string[] | null>(
    () =>
      searchParams.get(TEST_CLIPS_PARAM_NAME.TESTER_IDS)?.split(",") ?? null,
  );
  const [localActiveTasks, setLocalActiveTasks] = useState<string[] | null>(
    () =>
      searchParams.get(TEST_CLIPS_PARAM_NAME.TESTSTEP_IDS)?.split(",") ?? null,
  );
  const [localActiveTags, setLocalActiveTags] = useState<string[] | null>(
    () => searchParams.get(TEST_CLIPS_PARAM_NAME.TAGS)?.split(",") ?? null,
  );
  const [localIsTaglessSelected, setLocalIsTaglessSelected] = useState(
    searchParams.get(TEST_CLIPS_PARAM_NAME.UNTAGGED) === "true",
  );

  // TODO: move to usePersistentState to @utils
  const [localIsShowAi] = usePersistentState(
    PERSISTENT_STATE_KEY_SHOW_AUTOMATED_INSIGHTS,
    true,
  );

  useEffect(() => {
    // Delete page first so that it doesn't form part of the
    // change comparison at the end. A change in page should
    // not trigger a change in the search params.
    searchParams.delete(TEST_CLIPS_PARAM_NAME.PAGE);
    searchParams.sort(); // Sort to ensure that the order of the params doesn't matter.
    const originalSearchParamsString = searchParams.toString();

    // Remove all filter params
    searchParams.delete(TEST_CLIPS_PARAM_NAME.TESTER_IDS);
    searchParams.delete(TEST_CLIPS_PARAM_NAME.TESTSTEP_IDS);
    searchParams.delete(TEST_CLIPS_PARAM_NAME.TAGS);

    // Testers
    localActiveTesters?.length &&
      searchParams.append(
        TEST_CLIPS_PARAM_NAME.TESTER_IDS,
        localActiveTesters.join(","),
      );

    // Tasks
    localActiveTasks?.length &&
      searchParams.append(
        TEST_CLIPS_PARAM_NAME.TESTSTEP_IDS,
        localActiveTasks.join(","),
      );

    // Tags
    localActiveTags?.length &&
      searchParams.append(
        TEST_CLIPS_PARAM_NAME.TAGS,
        localActiveTags.join(","),
      );

    // Untagged
    if (localIsTaglessSelected !== true) {
      searchParams.delete(TEST_CLIPS_PARAM_NAME.UNTAGGED);
    } else {
      searchParams.set(
        TEST_CLIPS_PARAM_NAME.UNTAGGED,
        localIsTaglessSelected.toString(),
      );
    }

    // Show AI
    if (localIsShowAi === true) {
      searchParams.delete(TEST_CLIPS_PARAM_NAME.SHOW_AI);
    } else {
      searchParams.set(
        TEST_CLIPS_PARAM_NAME.SHOW_AI,
        localIsTaglessSelected.toString(),
      );
    }

    // Replace search params if they have changed.
    searchParams.sort(); // Sort to ensure that the order of the params doesn't matter.
    if (searchParams.toString() !== originalSearchParamsString) {
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    localActiveTesters,
    localActiveTasks,
    localActiveTags,
    localIsTaglessSelected,
    searchParams,
    setSearchParams,
    localIsShowAi,
  ]);

  function toggleActiveFilterState(
    stateSet: Dispatch<SetStateAction<string[] | null>>,
    value: string,
  ) {
    stateSet((prev) => toggleArrayItem<string>(prev, value));
  }

  function handleOpen(category: FilterOptionCategory) {
    setOpenCategory(category);
  }

  function handleClose() {
    setOpenCategory(null);
  }

  function handleClickTask(id: string) {
    toggleActiveFilterState(setLocalActiveTasks, id);
  }

  function handleClickClearTasks() {
    setLocalActiveTasks(null);
  }

  function handleClickTester(id: string) {
    toggleActiveFilterState(setLocalActiveTesters, id);
  }

  function handleClickClearTesters() {
    setLocalActiveTesters(null);
  }

  function handleClickTag(id: string) {
    toggleActiveFilterState(setLocalActiveTags, id);
  }

  function handleClickClearTags() {
    setLocalIsTaglessSelected(false);
    setLocalActiveTags(null);
  }

  function handleClickIsTaglessNotesSelected() {
    setLocalIsTaglessSelected((prev) => !prev);
  }

  function handleClickClearAll() {
    setLocalActiveTags(null);
    setLocalActiveTesters(null);
    setLocalActiveTasks(null);
    setLocalIsTaglessSelected(false);
  }

  const isAnyFilterActive =
    Boolean(
      localActiveTesters?.length ||
        localActiveTasks?.length ||
        localActiveTags?.length,
    ) || Boolean(tags?.isTaglessNotesSelected);

  const isDisplayEntriesWithoutTag =
    localIsTaglessSelected || Boolean(tags.isTaglessNotesCount);

  const isAnyOption = Boolean(
    tasks.options?.length || testers.options?.length || tags.options?.length,
  );

  return (
    <>
      <div className={cn(styles.filters, className)}>
        <div className={styles.heading}>Filters</div>
        {isAnyOption && (
          <>
            {!!tasks.options?.length && (
              <Dropdown
                activeCount={localActiveTasks?.length ?? 0}
                onOpen={() => handleOpen("task")}
                onClose={handleClose}
                icon={<SvgIconTask />}
                label={"Tasks"}
                isOpen={openCategory === "task"}
                optionIds={tasks.options.map((option) => option.id)}
                renderOption={(index) => {
                  const { description, count, type, position } = (
                    tasks.options as VideoClipTaskFilterItem[]
                  )[index];
                  return (
                    <TaskListItem
                      description={description}
                      count={count}
                      type={type}
                      position={position}
                    />
                  );
                }}
                activeOptions={localActiveTasks}
                onClickOption={handleClickTask}
                isActive={!!localActiveTasks?.length}
                onClickClear={handleClickClearTasks}
                isDisplayClearButton={Boolean(localActiveTasks?.length)}
              />
            )}
            {!!testers.options?.length && (
              <Dropdown
                activeCount={localActiveTesters?.length ?? 0}
                onOpen={() => handleOpen("tester")}
                onClose={handleClose}
                icon={<SvgIconTester />}
                label={"Testers"}
                isOpen={openCategory === "tester"}
                isActive={!!localActiveTesters?.length}
                optionIds={testers.options.map((option) => option.id)}
                renderOption={(index) => {
                  const { count, name } = (
                    testers.options as VideoClipTesterFilterItem[]
                  )[index];
                  return <TesterListItem count={count} name={name} />;
                }}
                activeOptions={localActiveTesters}
                onClickOption={handleClickTester}
                onClickClear={handleClickClearTesters}
                isDisplayClearButton={Boolean(localActiveTesters?.length)}
              />
            )}
            {!!tags.options?.length && (
              <Dropdown
                activeCount={
                  (localActiveTags?.length ?? 0) +
                  (localIsTaglessSelected ? 1 : 0)
                }
                onOpen={() => handleOpen("tag")}
                onClose={handleClose}
                icon={<SvgIconTag />}
                label={"Tags"}
                isOpen={openCategory === "tag"}
                isActive={
                  Boolean(localActiveTags?.length) || localIsTaglessSelected
                }
                optionIds={tags.options.map((option) => option.id)}
                renderOption={(index) => {
                  const { value, count } = (
                    tags.options as VideoClipTagFilterItem[]
                  )[index];
                  return <TagListItem value={`#${value}`} count={count} />;
                }}
                activeOptions={localActiveTags}
                onClickOption={handleClickTag}
                onClickClear={handleClickClearTags}
                isDisplayClearButton={
                  Boolean(localActiveTags?.length) || localIsTaglessSelected
                }
                footerOptionProps={
                  isDisplayEntriesWithoutTag
                    ? {
                        id: "footer", // required for keyboard selection
                        isActive: localIsTaglessSelected,
                        onClick: handleClickIsTaglessNotesSelected,
                        children: (
                          <TagListItem
                            isItalic
                            value="No tags"
                            count={tags.isTaglessNotesCount}
                          />
                        ),
                      }
                    : undefined
                }
              />
            )}
          </>
        )}
        {isAnyFilterActive && isAnyOption && (
          <button className={styles.clearButton} onClick={handleClickClearAll}>
            <SvgIconClear />
          </button>
        )}
      </div>
    </>
  );
}

/**
 * Tester ListItem
 */
function TesterListItem({
  name,
  country,
  gender,
  age,
  count,
  stars,
}: Omit<VideoClipTesterFilterItem, "id">) {
  return (
    <div className={styles.testerListItem}>
      <div className={styles.testerListItemHeadingDescriptionContainer}>
        <div className={styles.testerListItemName}>{name}</div>
        {(!isUndefined(country) ||
          !isUndefined(gender) ||
          !isUndefined(age)) && (
          <div className={styles.listItemDescription}>
            {[country, gender, age].filter((i) => !isUndefined(i)).join(" · ")}
          </div>
        )}
      </div>
      {!isUndefined(stars) && (
        <div className={styles.testerListItemMeta}>
          <div className={styles.testerListItemStars}>
            <SvgIconStar />
            {stars}
          </div>
        </div>
      )}
      <div className={styles.testerListItemMeta}>
        <span className={styles.listItemCount}>{count}</span>
      </div>
    </div>
  );
}

/**
 * VideoClipTagFilterItem ListItem
 */
function TagListItem({
  value,
  count,
  isItalic,
}: Omit<VideoClipTagFilterItem, "id"> & { isItalic?: boolean }) {
  return (
    <div className={styles.tagListItem}>
      <div
        className={cn(
          styles.tagListItemValue,
          isItalic && styles.tagListItemValue_italic,
        )}
      >
        {value}
      </div>
      <span className={styles.listItemCount}>{count}</span>
    </div>
  );
}

/**
 * Task ListItem
 */
function TaskListItem({
  description,
  count,
  type,
  position,
}: Omit<VideoClipTaskFilterItem, "id">) {
  const prefix = `${position + 1}. `;
  const heading = type.replace("_", " ");
  return (
    <div className={styles.taskListItem}>
      <div className={styles.taskListItemHeadingDescriptionContainer}>
        <div className={styles.taskListItemHeading}>{prefix + heading}</div>
        <div className={styles.listItemDescription}>{description}</div>
      </div>
      <span className={styles.listItemCount}>{count}</span>
    </div>
  );
}
