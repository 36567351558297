import { cn } from "@sys42/utils";

import { pluralizer } from "@/helpers";

import styles from "./styles.module.css";

/**
 * A component for the “AI” button.
 */
type AiToggleButtonProps = {
  className?: string;
  countAiInsights: number;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function AiToggleButton({
  className,
  countAiInsights,
  isChecked,
  onChange,
}: AiToggleButtonProps) {
  const title = isChecked
    ? `Hide ${pluralizer("AI insight")(countAiInsights, true)}`
    : `Show ${pluralizer("AI insight")(countAiInsights, true)}`;

  return (
    <label
      title={title}
      className={cn(
        styles.aiToggleButton,
        isChecked && styles.aiToggleButton_checked,
        className,
      )}
    >
      <span>
        <input type="checkbox" checked={isChecked} onChange={onChange} />
        AI
      </span>
    </label>
  );
}
