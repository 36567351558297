import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@sys42/utils";

import styles from "./styles.module.css";

type VideoStarProps = {
  className?: string;
  starred: boolean;
  color?: string;
  style?: React.CSSProperties;
  onClick?: (starred: boolean) => void;
};

export function VideoStar({
  className,
  starred,
  style,
  onClick,
}: VideoStarProps) {
  function handleClick() {
    if (typeof onClick === "function") {
      onClick(!starred);
    }
  }

  return (
    <button
      className={cn(className, styles.videoStar)}
      style={style}
      onClick={handleClick}
    >
      {starred ? (
        <FontAwesomeIcon icon={solid("heart")} fixedWidth />
      ) : (
        <FontAwesomeIcon icon={regular("heart")} fixedWidth />
      )}
    </button>
  );
}
