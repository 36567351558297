import { StylesConfig } from "react-select";

export const dropDownStyles: StylesConfig<any> = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "var(--border-radius-button)",
    border: state.isDisabled
      ? "2px solid var(--color-blue-100)"
      : "2px solid var(--color-blue-200)",
    boxShadow: state.isFocused ? "0 0 0 2px #15E0AF" : "",
    background: state.isDisabled ? "white" : provided.background,
    minHeight: "100%",
    "&:hover": {
      borderColor: "#D9D9E5",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    lineHeight: 1.4,
  }),
  placeholder: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isDisabled
      ? "var(--color-blue-300)"
      : "var(--color-primary-2)",
  }),
  // WARNING - the DropDown component initially did not have
  // any styles for this.
  option: (styles, state) => ({
    ...styles,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    backgroundColor: state.isSelected
      ? "var(--color-primary-1)"
      : state.isFocused
        ? "#E0FFF5"
        : "transparent",
    color: "var(--color-text-body)",
  }),
};
