import { useDispatch, useSelector } from "react-redux";

import { VideoStar } from "@/components/VideoStar";
import { RootState, Video } from "../types";

interface MenuProps {
  video: Video;
}

/**
 *
 * Menu
 */
export function SidebarVideoStar({ video }: MenuProps) {
  const dispatch = useDispatch();

  const starFetching = useSelector(
    (state: RootState) => state.videos.fetchingStar.indexOf(video.id) !== -1,
  );
  const unstarFetching = useSelector(
    (state: RootState) => state.videos.fetchingUnstar.indexOf(video?.id) !== -1,
  );
  const isStarred = (video.starred || starFetching) && !unstarFetching;

  function handleClickStarVideo() {
    dispatch({ type: "VIDEOS_STAR_REQUEST", id: video?.id });
  }
  function handleClickUnStarVideo() {
    dispatch({ type: "VIDEOS_UNSTAR_REQUEST", id: video?.id });
  }

  return (
    <VideoStar
      style={{ marginLeft: "auto" }}
      starred={isStarred}
      onClick={isStarred ? handleClickUnStarVideo : handleClickStarVideo}
    />
  );
}
